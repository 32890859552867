import React, { Component } from 'react'
import './Mainslider.css'
import { Link } from 'react-router-dom'
import Modalbases from '../paginas/Modalbases'

export class Mainslider extends Component {
  render() {
    return (
        <div className='imagen001'>
            <div className='container'>
                <div className='row altura'>
                    <div className='col-12 col-md-6'>
                    </div>
                    <div className='col-12 col-md-6 centradovertical text-white text-right'>
                            <p className="fecha bold">Desde el 19 de Marzo de 2022<br/> hasta el 19 de Noviembre de 2022</p>
                        <h1 className="bold xxl">CONCURSO</h1>
                        <h2 className="bold xl2">DE FOTOGRAFÍA</h2>
                        <h2 className="bold xl1 oranged">AVES DE COLOMBIA</h2>
                        <div className="bold ">
                            <h3> tus mejores 3 fotos</h3>
                            <h3>de aves en tu país</h3>
                        </div>
                        <Modalbases texto='Lee los Términos y Condiciones' />                           
                               
                        <Link className="nav-link btn btn-warning btn-orange mt-3" to="/registro">Quiero participar</Link>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

export default Mainslider