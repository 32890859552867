import React from 'react'
import './Premios.css'

function Premios() {
  return (
    <div className="container text-center py-4">
        <h4 className="pb-4">Premios</h4>
        <div id="premiosaqui" className='espaciomin'>
        <div id="anchor" className="element">
        <div className='premiar'></div>
        </div>

        </div>
    </div>
  )
}

export default Premios