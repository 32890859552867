import React from 'react'
import Secondaryslider from '../parts/Secondaryslider';
import './Transparencia.css'
import Bannerrojo from '../parts/Bannerrojo'
import fdfd from '../img/joven-transparencia.jpg'


const Transparencia = () => {
  return (
    <div>
        <Secondaryslider />
        <div className='espacio container py-4 izquierda'>
        <h3>Transparencia</h3>
        <hr></hr>
        <div className='row'>
          <div className='col-12 col-md-8'>
          <div className='organigrama'></div>
          <p>El proceso de evaluación de las fotografías concursantes garantiza seguridad de ambos lados de la organización. Por un lado evaluamos la metadata de las imágenes enviadas a concursar y constatamos imágenes de rango cromático similiar en buscadores de imágenes. Una vez superado este paso, la fotografía es evaluada en un número concreto de características, cada característica es analizada por un experto en dicho campo. Es importante recalcar que la identidad de los concursantes es desconocida para nuestros jueces, sino hasta el final del proceso donde la foto con más puntaje es la ganadora. <br/> Nota: Si la imagen concursante incurre en plagio, copia o alteración de la imagen, los jueces pueden bloquear al usuario o bannearlo temporalmente, dependiendo del caso. </p>
          </div>
          <div className='col-12 col-md-4'>
          <img src={fdfd} alt="transparencia" />
          </div>
        </div>
        </div>
        <Bannerrojo/>
    </div>
  )
}

export default Transparencia