import React, { PureComponent } from 'react'
import './Pasos.css'
import  pic0001 from '../img/ec55f7a350a.jpg'
import  pic0002 from '../img/ec55f2293b9.jpg'
import  pic0003 from '../img/ec55f8816a8.jpg'
import  pic0004 from '../img/ec55f9b2a14.jpg'
import  pic0005 from '../img/ec55fabc640.jpg'
import  pic0006 from '../img/ec55fddccdb.jpg'
import { Link } from 'react-router-dom'


export class Pasos extends PureComponent {
  render() {
    return (
      <div>
                <div className="bg-dark section text-light madera">
                <div className="container text-center">
                    <h4 className="pb-4">Pasos del Concurso</h4>
                    <div className="row">
                        <div className="col-6 col-md-2">
                            <div className="naipe">
                                <div className="numero">1</div>
                            <img className="naipe-img" src={pic0001} alt="pic0001" />
                            <div className="paddingcito">Comienzo del certamen<br/>

                                Fecha: 

                                19/03/2022<br/>

                                Tema:<br/>

                                Aves de tu país

                            </div>
                            <div></div>
                            </div>
                            <div><i className="fas fa-arrow-circle-right arrow"></i></div>

                        </div>

                        <div className="col-6 col-md-2"><div className="naipe">  <div className="numero">2</div>

                        <img className="naipe-img" src={pic0002} alt="pic0002" width={16}/>

                            <div className="paddingcito">Regístrate<br/>

                                en la 

                                <br/>

                                plataforma

                            </div><Link className="btn btn-warning btn-orange margenx" to="/registro">Registrate</Link>
                            
                            

                        </div>

                        <div><i className="fas fa-arrow-circle-right arrow-dos"></i></div>

                        </div>

                        <div className="col-6 col-md-2"><div className="naipe">  <div className="numero">3</div>

                        <img className="naipe-img" src={pic0003} alt="pic0003" />

                            <div className="paddingcito">Subir

                                hasta 3 fotografías

                                con cámara  

                                o celular

                                Se hará verificación

                                de autenticidad

                                de las fotos

                            </div>

                            </div>

                            <div><i className="fas fa-arrow-circle-right arrow"></i></div>

                            </div>

                        <div className="col-6 col-md-2"><div className="naipe">  <div className="numero">4</div>

                        <img className="naipe-img" src={pic0004} alt="pic0004" />

                            <div className="paddingcito">Para que tomemos

                                en cuenta

                                tus fotos

                                debes compartir

                                en redes sociales

                                desde nuestra

                                plataforma

                            </div>

                            </div>

                            <div><i className="fas fa-arrow-circle-right arrow-dos"></i></div>

                            </div>

                        <div className="col-6 col-md-2"><div className="naipe">  <div className="numero">5</div>

                        <img className="naipe-img" src={pic0005} alt="pic0005" />

                            <div className="paddingcito">Hasta 48 horas

                                antes de la

                                votación

                                puedes

                                modificar

                                descripción

                                o alguna foto

                            </div>

                        </div>

                        <div><i className="fas fa-arrow-circle-right arrow"></i></div>

                        </div>

                        <div className="col-6 col-md-2"><div className="naipe">  <div className="numero">6</div>

                        <img className="naipe-img" src={pic0006} alt="pic0006" />

                            <div className="paddingcito">Día de

                                la votación

                                síguelo por

                                ZOOM

                            </div>

                            </div>

                            </div>

                    </div>

                </div>

</div>




      </div>
    )
  }
}

export default Pasos