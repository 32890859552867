import React from 'react';
import Secondaryslider from '../parts/Secondaryslider';
import './Bases.css'
import Bannerrojo from '../parts/Bannerrojo'

const bases = () => {
  return (
    <div>
        <Secondaryslider />

        <div className='container py-4 izquierda'>
        <h3>Bases legales del Concurso de Fotografía </h3>
        <hr></hr>


<p className='justificar'>WELL BEING TOURS COLOMBIA SAS  con domicilio en calle 86 A No. 24 – 47  de Bogotá, Colombia Sur  América (en adelante, WELLBEING TOURS) es la organizadora de la promoción identificada como concurso mundial de  Fotografía  amateur  “ AVES DE TU PAIS “  (en adelante, Concurso de Fotografía), que tiene por objeto promocionar la primera edición del Álbum virtual de cromos  denominado  “ AVES DE COLOMBIA “ (en adelante, El Álbum ),  que ofrece como incentivos a sus coleccionistas la posibilidad de obtener en forma gratuita diferentes premios y regalos y que se realizara de acuerdo con las bases legales que se describen a continuación:  </p>

<h5>A.- PARTICIPANTES</h5>

<p>Esta convocatoria para participar en la Promoción va dirigida a todos los habitantes del planeta tierra, cumpliendo la condición de Participante únicamente quienes cumplan con los siguientes requisitos: </p>
<ul>
<li className='justificar'>Ser una persona  natural con documento de identidad y residente en Colombia.
Para participar en la Promoción se requiere cumplir con los protocolos especificados en las presentes Bases.</li>

<li className='justificar'>Quedan excluidos de participar en la Promoción todos los empleados, trabajadores  y colaboradores de WELLBEING TOURS, así como  los propietarios, socios y  empleados de las  empresas proveedoras y contratistas  de los productos promocionados y de sus cónyuges de cualquiera de ellos.</li>
</ul>


<h5>B.- PROTOCOLO  DE PARTICIPACIÓN</h5>

<p>Los interesados en participar en la  Promoción  deberán cumplir con los siguientes requisitos:</p>

<p className='justificar'>1.-Adquirir un ejemplar del Álbum “AVES DE COLOMBIA”  y como mínimo 5 sobres con 4 cromos cada uno</p>

<p className='justificar'>2.- Llenar la ficha de inscripción correspondiente  con los datos personales, nombre y apellidos completos, nacionalidad, documento de identificación, país, ciudad de residencia, edad, sexo, teléfono celular, correo electrónico y dirección de residencia, a la siguiente dirección:</p>

<div><strong>WELLBEING TOURS COLOMBIA<br/>
Concurso: ALBUM AVES DE COLOMBIA<br/>
Email: info@pikcontest.net</strong></div><br/>

<p className='justificar'>3.- Las fotografías  pueden ser  tomada con cualquier tipo de cámara semi o profesional o de celular de mediana o alta gama. Con resolución mínima de 720 DPI y especificar el tipo de cámara y la resolución utilizada en cada fotografía.</p>

<p className='justificar'>4.- Cada persona interesada  debe enviar tres fotografías originales  de  aves a color, de  su autoría, acompañada  con  el nombre  del autor,  resumen de su perfil,  país de origen y una breve descripción con información básica de tiempo, modo y lugar de la fotografía,  con  identificación  de la especie respectiva del ave; nombre común y si es posible  el nombre científico, además del correo electrónico y  teléfono móvil.</p>

<p className='justificar'>NOTA 1: Para los participantes menores de 14 años de edad o con alguna incapacidad mental o cognitiva, se requiere una carta de consentimiento firmada por sus padres o tutores, en cuyo caso ellos deberán enviar sus datos de identidad completos  para validar su autorización. </p>

<p className='justificar'>NOTA 2: Únicamente quedaran inscritos los interesados cuyos formularios de inscripción se reciban electrónicamente vía email  antes de las 23h: 59´m.  59´s,  hora de Colombia, del día  16 de Noviembre de 2022, fecha en la cual finaliza la Promoción, siempre y cuando se incluyan todos los requisitos exigidos. Serán rechazadas las inscripciones de aquellos participantes que no cumplan con todos los requisitos exigidos.
La inscripción para la participación en la Promoción es totalmente gratuita, ningún participante tiene que efectuar ningún tipo de compra adicional ni de gasto de cualquier otro tipo. </p>


<p>Las fotografías deben ser auténticas y tomadas por el participante.</p>
<ul>

<li> Todas las fotos serán validadas y no deben ser de libre uso por parte de bancos de imágenes ya sean gratis o pagas.</li>

<li> Cada fotografía enviada será analizada con su Metadata en motores especializados para validar su autenticidad.</li>

<li> La fotografía pudo haber sido tomada en cualquier tiempo, pero por el participante.</li>

<li> Pretender enviar una fotografía no de autoría del usuario registrado, implica la eliminación del registro del usuario sin derecho a reclamación o compensación alguna.</li>

<li> Los ganadores, sin ceder propiedad intelectual, autorizan a WELL BEING TOURS COLOMBIA SAS, su publicación en sus redes sociales haciendo mención a su autor.</li>

<li> Las fotos serán almacenadas en un repositorio de nuestro servidor y nos comprometemos a no hacer utilización , divulgación o uso alguno de ellas.</li>

<li> Al terminar el tiempo del concurso por cada región, un programa especializado mostrará al jurado las fotos sin hacer mención al autor, lugar, condiciones o tiempos. A medida que se van presentando, el jurado calificará en el sistema dando puntaje a cada una. A medida que avanza este proceso el sistema irá filtrando las de más alto puntaje de acuerdo a criterios tales como (calidad de la foto, oportunidad y dificultad de la misma).</li>

<li> No tomaremos la propiedad intelectual de las mismas y sólo se hará difusión o promoción de ellas con la debida autorización del autor.</li>
</ul>

<strong> Normas:</strong>

<ul>
<li></li> Se pide hacer publicaciones y comentarios solo hablando de nuestro tema especializado con fines científicos, turísticos y orientados a la protección de la biodiversidad y promoción turística. En la Web y en e mail de soporte y ayuda
<li></li> Solo se aceptan comentarios siempre y cuando sean con el debido respeto y adecuado lenguaje para los publicadores y los visitantes.
<li></li> No aceptamos publicaciones con fines de promoción comercial o económicas    (nadie en esta página debe entregar o se le pide donar dinero por ningún motivo).
<li></li> No se admiten comentarios políticos, religiosos, sexuales o de etnia.
<li></li> Ojalá los comentarios y publicaciones sean orientadas al fin de promover la biodiversidad y turismo en Colombia
<li></li> No se aceptan fotos de animales en cautiverio, enjaulados o muertos,  ( mejor dicho no en su ambiente natural). Salvo imágenes de observatorios o museos reales y legalmente existentes con fines meramente educativos y científicos.
<li></li> Aceptamos comentarios referidos en cuanto a errores en texto o referencias científicas equivocadas.
</ul>


</div>
<Bannerrojo />
<div className='container py-4 izquierda'>

<h5>C.- VIGENCIA</h5>

<p className='justificar'>Este incentivo de Promoción del Primer Concurso  mundial de Fotografía Amateur, tiene una vigencia desde el 19 de Marzo de 2022 hasta el 16 de Noviembre de 2022, para Colombia.</p>

<h5>D.- SELECCIÓN DE LOS GANADORES</h5>

<p>El top chart (lista top) de ganadores será resuelto desde el sistema informático del concurso.</p>
<p>¿Cómo funciona el sistema de puntaje de fotografías?</p>
<p>El sistema requiere subir hasta 3 imágenes a lo largo del concurso, cada imagen debe ir acompañada de datos que el sistema requiere por cada imagen y el participante debe agregar (en el formulario a la derecha de la imagen). Cuando el participante tiene la seguridad de enviar una foto, no necesita enviar las 3 al mismo tiempo, puede ir enviando una por una para que comience así el proceso participación. Una vez enviada una imagen no puede deshacer este paso. (candado en la imagen).</p>
<p>¿Cómo es el proceso del concurso?</p>
<p>Primeramente la imagen es supervisada por un verificador de autenticidad de imágenes. Éste se ayuda por un sistema que recoge la matedata de la imagen y también puede chequear en buscadores y otras herramientas de verificación de autenticidad. Nota: Si una imagen es falsa, el verificador se reserva el derecho de bannear al participante y éste último ya no podrá enviar ninguna otra imagen ni participar del concurso.</p>
<p>Si la imagen completa éste paso exitosamente, se procede a la puntuación de la imagen, desde su aspecto técnico-fotográfico, cómo así también el grado de dificultad de avistamiento o la calidad del espécimen. Para tal jurado contamos con fotógrafos y ornitólogos, respectivamente.</p>
<p>Esta puntuación maneja 4 dígitos y 2 decimales, lo que garantiza que dos fotografías no tengan el mismo puntaje. En caso de que ocurra un empate, se procederá a un desempate volviendo a revisar las fotos en disputa.</p>
<p>Cabe resaltar que durante todo el proceso de puntación de las imágenes, el jurado nunca conocerá el nombre de los participantes intervinientes.</p>


<h5>E.- PREMIOS</h5>

<p className='justificar'>1.-  A todos los participantes inscritos  en el Primer Concurso  mundial de Fotografía Amateur  que envíe una primera fotografía correspondiente se les obsequiara: Un árbol  nativo plantado en la amazonia colombiana, en la Reforestadora “Cooperación verde”, Identificado con el nombre  científico y común de la especie y el nombre del fotógrafo ganador, le enviaremos una  fotografía correspondiente, invitándolo a que contribuya con su mantenimiento mediante una donación anual de US$80.oo, comprometiéndonos  a enviarle anualmente una fotografía para que observe su desarrollo e invitándolo a realizar un viaje a Colombia a conocer a su árbol adoptivo  en el Amazonas, el cual aporta oxígeno al planeta y con el que se ayuda a la conservación y reforestación del amazonas. 
 <br/>
2.- Diploma de honor.<br/>
3.- Un ejemplar del Álbum  “AVES DE COLOMBIA “ mas  2 Sobres de cromos  del álbum,  “AVES DE COLOMBIA” “, con un contenido de 4 cromos cada uno, con el propósito de que inicien el llenado del Álbum.  </p>

<p>A LOS GANADORES DEL CONCURSO EN LAS DOS CATEGORIAS AMATEUR E INFANTIL 
(Hasta 14 años cumplidos como edad máxima)</p>

<p><strong>Primer Premio:</strong></p>

<p>1.- Una cámara  fotográfica  hasta  US $500.oo<br/>
2.- Diploma de honor<br/>
3.- Un formato  ampliado de tamaño grande del ave ganadora  impreso en papel.  y <br/>
4.- Una camiseta estampada con la fotografía del ave de su autoría ganadora del primer premio del concurso.<br/>
5.- Publicación destacada en las redes; acompañada de un breve resumen de la hoja de vida del autor y de la historia de la fotografía</p>

<p><strong>Segundo Premio:</strong></p>

<p>1.- Una cámara de fotografía hasta de US $450.oo<br/>
2.- Diploma de honor.<br/>
3.- Un formato  ampliado de tamaño mediano  impreso en papel del ave ganadora.  <br/>
4.- Una camiseta estampada  con la fotografía del ave de su autoría ganadora del segundo premio del concurso.<br/>
5.- Publicación destacada en las redes; acompañada de un breve resumen de la hoja de vida del autor y de la historia de la fotografía.</p>

<p><strong>Tercer Premio:</strong></p>

<p>1.- Una cámara de fotografía hasta de US $400.oo<br/>
2.- Diploma de honor.<br/>
3.- Un formato  ampliado de tamaño pequeño  impreso en papel del ave ganadora.<br/> 
4.- Una camiseta estampada con la fotografía del ave de su autoría ganadora del tercer premio del concurso.<br/>
5.- Publicación destacada en las redes; acompañada de un breve resumen de la hoja de vida del autor y de la historia de la fotografía.</p>

<p className='justificar'>NOTA: Se entiende por ganador para los efectos de lo previsto en la presente reglamentación, a todos aquellos participantes que obtengan cualquiera de los premios que ofrece esta Promoción.
La lista de los ganadores aparecerá publicada a más tardar el día 30 de Noviembre  de 2022 y continuara publicada durante los siguientes 6 meses, en la página de WELL BEING TOURS COLOMBIA SAS. www.wellbeingtours.net.</p>

<p><strong>Programación:</strong></p>
<p className='justificar'>Recepción de fotografías entre el 19 de Marzo de 2022 hasta el 19 de Noviembre  de 2022 a las 23 horas – 59 minutos y 59 segundos.
Publicación de ganadores  Noviembre 30 de 2022. <br/>
Entrega y envío de Premios  entre el 20 de Noviembre  y el 16 de Diciembre   de 2022.</p>
 
<p>Jurado Calificador de fotografía:</p>

<p className='justificar'>Conformado por 4 fotógrafos Profesionales de gran trayectoria y reconocimiento:<br/>
José Miguel Gómez, Wilfredo Garzón, Andrés Morales,  Filiberto Pinzón.<br/></p>

<p>Jurado Calificador de Ornitología:</p>

<p>Conformado por 2  Ornitólogos:
Nicolás Téllez Colmenares, Miguel Ángel Muñoz.</p>

<h6>Breve reseña de los miembros del Jurado:</h6>

<p><strong>José Miguel Gómez,</strong> Comunicador Social Periodista, Especialidad en Fotoperiodismo, Reportajes Gráficos, Documentales y Foto paisajismo. TV Conferencista. Profesor de Fotografía Periodística y Paisajística.<br/>
Fotoperiodista en deportes, noticia general, entrevista, crónica, reportaje y documental. Editor de fotografía y organizador de coberturas periodísticas. Experto en cámaras fotográficas especialmente en la marca Canon. Profesor y conferencista de fotoperiodismo, investigador de la composición y el lenguaje de la fotografía digital. Manejo del photoshop. Fotógrafo caminante, escritor y viajero de parques naturales a lo largo del continente americano.</p>

<p><strong>Andrés Morales Ospina “Andros”</strong>, Licenciado. en Educación Física, Guía De turismo Profesional, caminante y fotógrafo de naturaleza con más de 25 años. Ha recorrido y fotografiado los paisajes más bellos y secretos de Colombia y del mundo; y al igual que su maestro Andrés Hurtado García, el mayor conocedor de los caminos de Colombia, su filosofía es conocer para amar y amar para cuidar nuestro territorio.</p>

<p><strong>Nicolas Téllez Colmenares</strong>,  Biólogo y Magister graduado de la Universidad Nacional de Colombia, especializado en ornitología y en investigación. Gran parte de su experiencia radica en el estudio comportamental y ecológico de aves. También se ha desempeñado desde 2013 como guía de turismo en varias regiones de Colombia para diversas empresas del sector.</p>

<p><strong>Miguel Ángel Muñoz Amaya, </strong>
Biólogo de la Universidad Nacional de Colombia con enfoque en el estudio de la avifauna. Experiencia en campo en la realización de documentales de colibríes para Warner Bros,Netflix y Apple TV. Es además guía profesional en avIturismo y consultor ambiental.</p>

<p><strong>Wilfredo Garzón Paipilla, </strong>

Wilfredo Garzón "Wilo".  Diseñador Industrial, guía profesional, 29 años de experiencia.  Más de 320 caminatas y 50 excursiones con alumnos del Colegio Champagnat Bogotá. Más de 800 caminatas y excursiones como guía. Cinco travesías a pie en Europa recorriendo más de 2700 kilómetros y ascensos a los picos Kilimanjaro y Aconcagua.</p>


<p><strong>Filiberto Pinzón Acosta, </strong>

Fotógrafo profesional con mas de 48 años de experiencia profesional y 40 de ellos con el diario El Tiempo, 
egresado de la Escuela de Bellas Artes de la Universidad Nacional de Colombia, gran constructor del turismo en el país especialmente mediante el programa de televisión “ EL ROLLO DE FILI” en City TV y el canal de TV El Tiempo; con mas de 130 programas y muchas galerías de fotos e innumerables fotografías para casi todas las revistas de la Casa Editorial. Pionero de la fotografía digital en Colombia, ha publicado 7 libros. Creo la Escuela de Fotografía Los Párvulos de Fili transmitiendo así sus conocimientos. Ganador de importantes concursos de fotografía a nivel nacional e internacional, el ultimo en el concurso LatamDigital 2021.</p>




<h5>F.- ENTREGA DE PREMIOS Y NOTIFICACION </h5>

<p className='justificar'>WELLBEING TOURS contactara a los tres ganadores de cada categoría (Amateur e infantil),  por región  de los premios otorgados en el Primer Concurso mundial de Fotografía,  para hacerles entrega formal del premio otorgado a cada uno de ellos de manera gratuita. 
WELL BEING TOURS COLOMBIA SAS., no se hará responsable por la imposibilidad de hacer entrega de un premio en el caso que la información suministrada por el participante resulte errónea o incompleta, o no correspondan con los enviados en el formulario de inscripción  con su identidad,  respecto a los datos de dirección ciudad, país y teléfono de contacto; en tal caso dicho participante perderá el derecho a recibir el premio. 
La garantía sobre los premios otorgados a los ganadores no es responsabilidad de WELLBEING TOURS, correspondiéndole al fabricante del equipo mediante la tarjeta de garantía incluida, siempre y cuando la reclamación este amparada en las condiciones del fabricante para que en caso tal  de presentar alguna falla el equipo, el ganador se contacte con  la red de servicio técnico local correspondiente en su ciudad  o en su país de residencia.  </p>

<h5>G.- SEGURIDAD Y PROTECCIÓN DE DATOS PERSONALES</h5>

<p className='justificar'>Temporalmente los participantes a este Concurso de Fotografía conformaran un archivo con sus datos personales cuyo responsable es WELL BEING TOURS COLOMBIA SAS y cuya  única función es el desarrollo de un control de inscripción de las cartas de los participantes y el de los envíos posteriormente de los premios a los ganadores. Este archivo será eliminado una vez finalizada la Promoción.<br/>
Todos los participantes autorizan a WELL BEING TOURS  COLOMBIA SAS en forma expresa, para incorporar en dicho archivo sus datos personales, igualmente para darles un tratamiento acorde a la finalidad indicada.  En cumplimiento con la normatividad existente aplicada en materia de protección de datos personales   el interesado podrá en cualquier momento ejercer los derechos que para el caso le correspondan (consulta, acceso, corrección, rectificación, oposición, supresión, limitación y portabilidad, según lo previsto en la vigente normativa de la protección de datos),  mediante una  solicitud  escrita enviada a la dirección de  domicilio de WELL BEING TOURS COLOMBIA SAS, que se encuentra publicada en el encabezado de  este documento, documento que debe estar debidamente firmado, incluyendo su nombre y apellidos indicando claramente el objetivo de la solicitud. En el caso de  menores de 14 años, o de personas sujetas a patria potestad o tutela, la solicitud debe realizarla, el padre, la madre o el tutor.   
.</p>   


<h5>H.- RESERVAS</h5>

<p>WELL BEING TOURS COLOMBIA SAS, se reserva los derechos respecto al desarrollo y ejecución de la Promoción,  en los siguientes aspectos:</p>

<ul>
<li className='justificar'> Aplazar, modificar o suspender la Promoción, siempre que exista una causa justificada y con el compromiso de comunicar con suficiente tiempo y antelación las nuevas condiciones o bases del concurso, o en caso tal la anulación definitiva.</li>

 <li className='justificar'> Excluir mediante expulsión a los participantes que actúen fraudulentamente en el concurso, bien sea que este comprobado  el hecho o que existan suficientes sospechas fundadas, sin más requisito que la oportuna comunicación y explicación de los motivos de la expulsión.</li>

<li className='justificar'> Ejercer acciones legales en caso de existir cualquier intento que cause daño de manera deliberada al contenido o funcionamiento del concurso, o cualquier acción que se pueda considerar ilícita. 
Que se establezcan negocios independientes sobre este tema. </li>

<li className='justificar'>  No permitir que se establezcan negocios independientes sobre el concurso o cualquier tipo de sistema de especulación, recurriendo si es necesario a las acciones legales pertinentes. 
Ni WELL BEING TOURS COLOMBIA SAS, ni DIGITAL ALBUMS COLOMBIA, ni sus sociedades matrices, subsidiarias, filiales, asociadas o sucursales, ni tampoco ninguno de sus socios, ni de sus empleados, ni de los agentes y/o empleados, ni de sus proveedores, serán responsables de cualquier daño o perjuicio sufrido por algún participante en el concurso  y/o de cualquier tercero y que pidiera derivarse de la participación en la Promoción o del uso de cualquiera de los, premios que otorga el concurso. </li> 

<li className='justificar'> WELL BEING TOURS COLOMBIA SAS se reserva el derecho de publicar todas las fotografías enviadas al concurso por cualquier participante, cuya propiedad y derechos legales le quedan otorgados a WELL BEING TOURS COLOMBIA SAS desde el momento del recibo  de cada fotografía para participar en el concurso.  WELL BEING TOURS COLOMBIA SAS reconocerá a cada fotografía la autoría correspondiente en cada publicación. </li> 

</ul>

<h5>I.- NORMATIVA FISCAL</h5>

<p className='justificar'>Los premios otorgadlos por el concurso quedan sujetos a la retención o al ingreso a cuenta que corresponda de acuerdo a  la normatividad fiscal vigente. En el caso correspondiente la retención o ingreso a cuenta será asumido por WELL BEING TOURS COLOMBIA SAS,  quien pagara a Hacienda Publica el dinero correspondiente por impuestos que correspondan a nombre del ganador, a quien le extenderá el certificado  correspondiente incluyendo los datos del premio de la retención o ingreso. </p> 

<h5>L.- JURISDICCIÓN COMPETENTE</h5>

<p className='justificar'>La presente reglamentación y bases del concurso se rigen por la ley colombiana. En el caso de presentarse alguna controversia con relación a la interpretación y/o ejecución de la presente reglamentación o de las bases de este concurso, tanto WELL BEING TOURS COLOMBIA SAS como  los participantes se someten expresamente para su resolución a los juzgados y tribunales de Bogotá, Colombia, renunciando expresamente a cualquier fuero diferente que pudiera corresponderles. </p> 

<h5>M.- ACEPTACIÓN DE LAS BASES.</h5>

<p className='justificar'>Con la simple inscripción y participación en el concurso se constituye en aceptación integra del participante, de las presente reglamentación y bases del concurso.</p>
</div>
<Bannerrojo />
    </div>
  )
}

export default bases