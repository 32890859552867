import React from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'
import Salir from './Salir';
import $ from 'jquery'

  let correo_session='';

 fetch("https://pikcontest.net/data-api/username.php?consultar=" + localStorage.getItem('cod_user') )
    .then(respuesta=>respuesta.json())
    .then((datosRespuesta)=>{

      correo_session = 'Hola, ' + datosRespuesta[0].nombre;
      $('#ide_usuario').html(correo_session);
  })
  .catch(console.log)

const navbar = () => {
  return (
    <div>
        <nav className="navbar navbar-expand-lg navbar-dark fondocolor">
  <div className="container">
    <Link className="navbar-brand tamanio" to="/inicio"><span className='orange'>Pik</span><span>contest</span></Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
      <li className="nav-item">
        <Link className="nav-link" to="/inicio">Inicio</Link>
        </li>
        <li className="nav-item">
        <Link className="nav-link" to="/bases">Bases</Link>
        </li>
        <li className="nav-item">
        <Link className="nav-link" to="/ganadores">Ganadores</Link>
        </li>
        <li className="nav-item">
        <Link className="nav-link" to="/transparencia">Transparencia</Link>
        </li>
        <li className="nav-item">
        <Link className="nav-link" to="/contacto">Contacto</Link>
        </li>

      </ul>
      { localStorage.getItem('cod_user') !== null
        ?<div id="divSalir" className='text-white'><a className='nodeco' href='https://pikcontest.net/participante.php'><span id="ide_usuario"></span></a> <Salir /></div>
        :<div className='text-white'> <Link className="nav-link nodeco" to="/login"><i class="fa-solid fa-right-to-bracket"></i> Ingresar</Link></div>
      }
    </div>
   
  </div>
</nav>
    </div>
  )
}

export default navbar