import React from 'react'
import './Verpremios.css'
import { Link } from 'react-scroll'


function Verpremios() {
  return (
    <div className='verpremios'>
        <div className='container'>
            <div className='row'>
                <div className='col-12 col-md-6 mmrr'>magníficos premios</div>
                <div className='col-12 col-md-6 mmll'>
                  <button type="button" class="btn btn-warning btn-orange"> 
                    <Link activeClass="active" className="test6 nodecor" to="anchor" spy={true} smooth={true} duration={200}>Ver premios</Link>
                  </button>
                </div>
            </div>
        </div>
    </div>
  )
}
export default Verpremios