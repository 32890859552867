import React, { Component } from 'react'
import './Secondaryslider.css'

export class Secondaryslider extends Component {
  render() {
    return (
        <div className='imagen002'>
            <div className='container'>
                <div className='row altura'>
                    <div className='col-12 col-md-6'>
                    </div>
                    <div className='col-12 col-md-6 centradovertical text-white text-right'>
                           
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

export default Secondaryslider