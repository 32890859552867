import React from 'react';
import './Registro.css'
import { Link } from 'react-router-dom'
import $ from "jquery";
import Modalbases from './Modalbases';
import './bigbox.css';

const enviarDatos = (e) =>{
  e.preventDefault();
  
  let var_terminos = 'NO';
  if( $('#terminos').prop('checked') ) {
    var_terminos = 'SI';
  }
  // creamos un objeto con la información a registrar
  const datosEnviar = {
    nombre:     $('#nombre').val(),
    apellido:   $('#apellido').val(),
    correo:     $('#correo').val(),
    tel:        $('#tel').val(),
    con:        $('#con').val(),
    con2:       $('#con2').val(),
    categoria:  $('#categoria').val(),
    fechan:     $('#fechan').val(),
    terminos:   var_terminos
  }

  

  fetch("https://pikcontest.net/data-api/username.php?registro=1",{
    method: 'POST',
    body: JSON.stringify(datosEnviar) // Enviamos el objeto, por body de la función fetch
  })
  .then(respuesta=>respuesta.json())
  .then((datosRespuesta)=>{
    
    bigbox_abrir();
    
    if(datosRespuesta.success === 1){

      $('.bigbox-titulo').html('<i class="fas fa-check"></i> Error!');
      $('.bigbox-texto').html('Se ha registrado con éxito. Por favor verifique su correo electrónico. Diríjase a su bandeja de entrada.');

      // inicializamos el formulario
      $('#nombre').val('');
      $('#apellido').val('');
      $('#correo').val('');
      $('#tel').val('');
      $('#con').val('');
      $('#con2').val('');
      $('#categoria').val('');
      $('#fechan').val('');
      $('#terminos').val('');


    }else if(datosRespuesta.success === 2){
      $('.bigbox-titulo').html('<i class="fas fa-exclamation-triangle"></i> Error!');
      $('.bigbox-texto').html('Las contraseñas deben ser iguales');
    }else if(datosRespuesta.success === 3){
      alert('');
      $('.bigbox-titulo').html('<i class="fas fa-exclamation-triangle"></i> Error!');
      $('.bigbox-texto').html('El correo ya se encuentra registrado');
    }else if(datosRespuesta.success === 4){
      $('.bigbox-titulo').html('<i class="fas fa-exclamation-triangle"></i> Error!');
      $('.bigbox-texto').html('El nombre, apellido y categoría son datos requeridos');
    }else if (datosRespuesta.success === 6){
      $('.bigbox-titulo').html('<i class="fas fa-exclamation-triangle"></i> Error!');
      $('.bigbox-texto').html('Tienes que aceptar los Términos y Condiciones');
    }

  })
  .catch(console.log)
}

const fechaGana = () =>{    $('#fechan').attr('type','date'); }
const fechaPierde = () =>{  $('#fechan').attr('type','text'); }

const bigbox_abrir = ( ) => {
  $('.bigbox-contenedor').css('display','block');
  $('.bigbox-contenedor').removeClass('bigbox-salida');
  $('.bigbox-contenedor').addClass('bigbox-entrada');
}

const bigbox_cerrar = () => {
  $('.bigbox-contenedor').removeClass('bigbox-entrada');
  $('.bigbox-contenedor').addClass('bigbox-salida');
  $('.bigbox-contenedor').css('display','none');
}

const Registro = () => {
  return (
    <div>
      <div className='container-flex'>
        <div className='row'>
          <div className='col-6 negro'></div>
          <div className='col-6 vertical formcelu'>
            <div className='formregistro vertical'>
              <h4>Registro</h4>
              <p>Regístrate para concursar y gana increíbles premios!</p>
            <form>
              <div className='row margen'>
                <div className='col-12 col-md-6 py-1'><input id="nombre" className="form-control" type="text" placeholder="Nombres" aria-label="default input example" /></div>
                <div className='col-12 col-md-6 py-1'><input id="apellido" className="form-control" type="text" placeholder="Apellidos" aria-label="default input example" /></div>
              </div>
              <div className='row margen'>
                <div className='col-12 col-md-6 py-1'><input id="tel" className="form-control" type="text" placeholder="Teléfono" aria-label="default input example" /></div>
                <div className='col-12 col-md-6 py-1'><input id="correo" className="form-control" type="email" placeholder="E-mail" aria-label="default input example" /></div>
              </div>
              <div className='row margen'>
                <div className='col-12 col-md-6 py-1'>
                  <select className="form-select" id="categoria" aria-label="Default select example">
                    <option value="" selected>Seleccione su categoría</option>
                    <option value="1">Infantil (hasta 12 años)</option>
                    <option value="2">Amateur</option>
                    <option value="3">Profesional</option>
                  </select></div>
                <div className='col-12 col-md-6 py-1'><input id="fechan" className="form-control " autofocus="autofocus" type="text" placeholder="Fecha de nacimiento" aria-label="default input example" onFocus={fechaGana} onBlur={fechaPierde} /></div>
                <script>
                 $("#fechan").focus();
              </script>
              </div>
              <div className='row margen'>
                <div className='col-12 col-md-6 py-1'><input id="con" className="form-control" type="password" placeholder="Escriba una contraseña" aria-label="default input example" /></div>
                <div className='col-12 col-md-6 py-1'><input id="con2" className="form-control" type="password" placeholder="Repita la contraseña" aria-label="default input example" /></div>
              </div>
              <div className='row margen text-left margenleft'>
                <div className="form-check ">
                  <input className="form-check-input"  type="checkbox" value="1"  id="terminos"/>
                  <label className="form-check-label" for="flexCheckDefault">
                    <Modalbases texto='Aceptar Términos y Condiciones' estilo='text-registro' />
                  </label>
                  
                </div>
              </div>
              <div className='row margen'>
              <button type="submit" onClick={enviarDatos} className="btn btn-warning btn-orange my-4">Registrarme</button>

              <p>Ya tienes una cuenta?  <Link className='btonblack'  to="/login">INGRESAR</Link></p>

              </div>

              </form>
            </div>
          </div>
        </div>
      </div>


      <div className='bigbox-contenedor'>
        <div className="bigbox-cerrar"><i class="fas fa-times" onClick={bigbox_cerrar}></i></div>  
        <div className="bigbox-contenido">
          <span className="bigbox-titulo"></span>
          <span className="bigbox-texto"></span>
        </div>
      </div>

    </div>
  )
}



export default Registro