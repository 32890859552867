import React from 'react'
import Secondaryslider from '../parts/Secondaryslider';
import './Ganadores.css'
import Bannerrojo from '../parts/Bannerrojo'

const Ganadores = () => {
  return (
    <div>
        <Secondaryslider />
        <div className='espacio container py-4 izquierda'>
        <h3>Ganadores</h3>
        <hr></hr>
        <div className='banneresperar'>
          <div><h4>Queda atento</h4></div>
          <div className='square03'>Aquí publicaremos el listado de ganadores al finalizar el concurso de fotografía.</div>
          <div className='pt-3'>Finaliza el 19/11/2022</div>
        </div>
        </div>
        <Bannerrojo/>
    </div>
  )
}

export default Ganadores