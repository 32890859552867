
import React from 'react';
import './App.css';
//import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/navegacion/Navbar';
import bases from './components/paginas/Bases';
import contacto from './components/paginas/Contacto';
import ganadores from './components/paginas/Ganadores';
import transparencia from './components/paginas/Transparencia';
import inicio from './components/paginas/Inicio';
import registro from './components/paginas/Registro'
import login from './components/paginas/Login'



function App() {
  return (
    <div className="App">
        <Router>
        <Navbar/>
        <Switch>
          <Route path='/' exact component={inicio} />
          <Route path='/bases' component={bases} />
          <Route path='/contacto' component={contacto} />
          <Route path='/ganadores' component={ganadores} />
          <Route path='/transparencia' component={transparencia} />
          <Route path='/inicio' component={inicio} />
          <Route path='/registro' component={registro} />
          <Route path='/login' component={login} />
        </Switch>

        </Router>
    </div>
  );
}

export default App;
