import React from 'react'
import './Modalbases.css'

const Modalbases = (props) => {

    const New_estilo='touchable bases ' + props.estilo;


  return (
    <div>
        <h6 type="text" id="titulo" className={ New_estilo } data-bs-toggle="modal" data-bs-target="#exampleModal2">
            {props.texto}
        </h6>
   
   
                                   <div className="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                   <div className="modal-dialog modal-dialog-scrollable modal-lg text-dark">
                                       <div className="modal-content">
                                       <div className="modal-header">
                                           <h5 className="modal-title" id="exampleModalLabel">Términos y Condiciones del concurso</h5>
                                           <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                       </div>
                                       <div className="modal-body text-left">
   <ul>
   <li>Para participar en el concurso debe primero registrarse y adquirir el álbum  “AVES DE COLOMBIA”. Dicha compra lo autoriza a participar.</li>
   
   <li>Todo participante debe referir si es fotógrafo profesional, ornitólogo especializado o simple aficionado.</li>
   
   <li>Tu foto no necesariamente debe ser tomada con cámara profesional y se permite cualquier dispositivo de captura de imágenes.</li>
   
   <li>Cada persona interesada puede enviar hasta tres (3) fotografías originales de su autoría; adjunta en el envío un texto donde indiques tu información personal (nombre como autor, correo electrónico, número de contacto, país de origen y resumen de tu perfil profesional si lo tiene).</li>
   
   <li>Durante la vigencia del concurso, el autor puede reemplazar hasta por una sola vez la cada fotografía enviada.</li>
   
   <li>Debe enviar adjunto a las fotos una  breve descripción básica de cuándo, cómo y dónde tomaste la fotografía; si conoces el nombre del ave también puedes anexarla al envío.</li>
   
   <li>Las fotografías deben ser auténticas y tomadas por el participante.</li>
   
   <li>Todas las fotos serán validadas y no deben ser de libre uso por parte de bancos de imágenes ya sean gratis o pagas.</li>
   
   <li>Cada fotografía enviada será analizada con su Metadata en motores especializados para validar su autenticidad.</li>
   
   <li>La fotografía pudo haber sido tomada en cualquier tiempo, pero por el participante.</li>
   
   <li>Pretender enviar una fotografía no de autoría del usuario registrado, implica la eliminación del registro del usuario sin derecho a reclamación o compensación alguna.</li>
   
   <li>Los ganadores, sin ceder propiedad intelectual, autorizan a WELL BEING TOURS COLOMBIA SAS, su publicación en sus redes sociales haciendo mención a su autor.</li>
   
   <li>Las fotos serán almacenadas en un repositorio de nuestro servidor y nos comprometemos a no hacer utilización , divulgación o uso alguno de ellas. </li>
   
   <li>Al terminar el tiempo del concurso por cada región, un programa especializado mostrará al jurado las fotos sin hacer mención al autor, lugar, condiciones o tiempos. A medida que se van presentando, el jurado calificará en el sistema dando puntaje a cada una. A medida que avanza este proceso el sistema irá filtrando las de más alto puntaje de acuerdo a criterios tales como (calidad de la foto, oportunidad y dificultad de la misma).</li> 
   
   <li>No tomaremos la propiedad intelectual de las mismas y sólo se hará difusión o promoción de ellas con la debida autorización del autor.</li>
   </ul>
   
   <h5>Normas:</h5>
   
   <ul>
   <li>Se pide hacer publicaciones y comentarios solo hablando de nuestro tema especializado con fines científicos, turísticos y orientados a la protección de la biodiversidad y promoción turística. En la Web y en e mail de soporte y ayuda.</li>
   <li>Solo se aceptan comentarios siempre y cuando sean con el mayor respeto y adecuado lenguaje para los publicadores y los visitantes.</li>
   <li>No aceptamos publicaciones con fines de promoción comercial o económicas     (nadie en esta página debe entregar o se le pide donar dinero por ningún motivo).</li>
   <li>No se admiten comentarios políticos, religiosos, sexuales o de etnia.</li>
   <li>Ojalá los comentarios y publicaciones sean orientadas al fin de promover la biodiversidad y turismo en Colombia</li>
   <li>No se aceptan fotos de animales en cautiverio, enjaulados o muertos,  ( mejor dicho no en su ambiente natural). Salvo imágenes de observatorios o museos reales y legalmente existentes con fines meramente educativos y científicos.</li>
   <li>Aceptamos comentarios referidos en cuanto a errores en texto o referencias científicas equivocadas.</li>
   </ul>
   
                                       </div>
                                       <div className="modal-footer">
                                           <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                       </div>
                                       </div>
                                   </div>
                                   </div>
             
         </div>
  )
}

export default Modalbases