import React from 'react'
import './Footer.css'
import logopwr from './components/img/logo-vento-vasquez.png'; // with import
import welllogo from './components/img/logos_well_digital.png'



function Footer() {
  return (
      <div>
        <div className="footerx py-4">
            <div className='container text-gray'>
            <div className='row'>
                <div className='col-12 col-md-4'>
                    <h3>Sobre el concurso de fotografía</h3>
                    <p>Pikcontest es una plataforma de concursos de fotografía de diversas temáticas. Forma parte de la suite de productos de Digital Albums. Todos los derechos reservados. COPYRIGHT MMXXII.</p>
                </div>
                <div className='col-12 col-md-4'>
                    <h3>Menu</h3>
                    <ul>
                    <li>Inicio</li>
                    <li>Bases</li>
                    <li>Ganadores</li>
                    <li>Transparencia</li>
                    <li>Contacto</li>
                    </ul>
                    </div>
                <div className='col-12 col-md-4 acomodo'>
                <div className='mb-3'><img className='widthlogo' src={welllogo} alt="Logo" /></div>
                Pikcontest es propiedad de Wellbeingtours y DigitalAlbums
                </div>
                </div>
            </div>
            
        </div>

        <div className='power'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                    Power by <img src={logopwr} width="70" alt="Ven-Vas" />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer