import React from 'react'
import './Salir.css'
//import { useHistory } from 'react-router-dom';
//import '../paginas/Inicio'
//import { Link } from 'react-router-dom'

function Salir() {
  /*   let history = useHistory()*/
   // function handleClick() {
       // localStorage.removeItem('cod_user');
       // history.push("/inicio") // esta como antes, no compilo si es igual
        //window.location.reload();
   // } 

    return (
      <span>
        <a href='https://pikcontest.net/cerrar.php' className='saliendo espaciox nodeco'><i class="fa-solid fa-right-from-bracket"></i> Salir</a>
      </span>
    )
}


export default Salir