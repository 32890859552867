import React from 'react'
import './bigbox.css'
import { Link } from 'react-router-dom'
import $ from "jquery";

const ingresar = (event) => {

  event.preventDefault();

  var md5 = require('md5');

  const usu = $('#usu').val();
  const con = md5($('#con').val());

  if(usu === ''){
    bigbox_abrir();
  }else{
    fetch("https://pikcontest.net/data-api/username.php?usu=" + usu + "&con=" + con)
      .then(respuesta=>respuesta.json())
      .then((datosRespuesta)=>{
        if(Object.keys(datosRespuesta).length === 0){
          bigbox_abrir();
        }else{
          console.log('Bienvenido ', datosRespuesta[0].nombre);
          localStorage.setItem("cod_user", datosRespuesta[0].correo);
          window.location.href = 'login.php'; //one level up
        }
      })
      .catch()
  }
}



const bigbox_abrir = ( ) => {
  $('.bigbox-contenedor').css('display','block');
  $('.bigbox-contenedor').removeClass('bigbox-salida');
  $('.bigbox-contenedor').addClass('bigbox-entrada');
  

}

const bigbox_cerrar = () => {
  
  $('.bigbox-contenedor').removeClass('bigbox-entrada');
  $('.bigbox-contenedor').addClass('bigbox-salida');
  $('.bigbox-contenedor').css('display','none');
}

function Login() {
  return (
    <>
    
    <div className='container-flex'>
      <div className='row'>
        <div className='col-6 negro'></div>
        <div className='col-6 vertical formcelu'>
          <div className='formregistro vertical'>
            <h4>Ingresar</h4>
            <p>Ingresa a tu cuenta Pikcontest</p>
            <p id="respuesta"></p>
            <div className='row margen'>
              <div className='col-12 col-md-6 py-1'><input id="usu" className="form-control" type="email" placeholder="E-mail" aria-label="default input example" /></div>
              <div className='col-12 col-md-6 py-1'><input id="con" className="form-control" type="password" placeholder="Clave" aria-label="default input example" /></div>
            </div>
            <div className='row margen'>
            <button onClick={ ingresar } className="btn btn-warning btn-orange my-4">Ingresar</button>
            <p>No tienes una cuenta?  <Link className='btonblack'  to="/registro">REGISTRARME</Link></p>
            </div>
           
          </div>
        </div>
      </div>
    </div>


    <div className='bigbox-fondo'></div>

    <div className='bigbox-contenedor'>
                
        <div className="bigbox-cerrar"><i class="fas fa-times" onClick={bigbox_cerrar}></i></div>
                
        <div className="bigbox-contenido">
            <span className="bigbox-titulo"><i class="fa-solid fa-triangle-exclamation"></i> Error!</span>
            <span className="bigbox-texto">Usuario o contraseña incorrecto/s</span>
        </div>

    </div>

  </>
  )
}

export default Login