import React from 'react'
import Secondaryslider from '../parts/Secondaryslider';
import './Contacto.css'
import $ from 'jquery'
import './bigbox.css'


const enviarDatos = (e) =>{
  e.preventDefault();

  // creamos un objeto con la información a registrar
  const datosEnviar = {
    nombre: $('#nombre').val(),
    correo: $('#correo').val(),
    tel: $('#tel').val(),
    texto: $('#texto').val(),
    dpto: $('#dpto').val(),
  }

  fetch("https://pikcontest.net/data-api/username.php?contacto=1",{
    method: 'POST',
    body: JSON.stringify(datosEnviar) // Enviamos el objeto, por body de la función fetch
  })
  .then(respuesta=>respuesta.json())
  .then((datosRespuesta)=>{
    console.log(datosRespuesta.success);
    if(datosRespuesta.success === 1){
      //alert('Se ha enviado la consulta con éxito');

      // inicializamos el formulario
      $('#nombre').val('');
      $('#correo').val('');
      $('#tel').val('');
      $('#texto').val('');
      $('#dpto').val('');

      bigbox_abrir();
      $('.bigbox-titulo').html('<i class="fas fa-check"></i> Éxito!');
      $('.bigbox-texto').html('Se ha enviado la consulta con éxito');

    }else if(datosRespuesta.success === 2){

      bigbox_abrir();
      $('.bigbox-titulo').html('<i class="fas fa-exclamation-triangle"></i> Error!');
      $('.bigbox-texto').html('No se permiten datos duplicados en los envios de mensajes de contactanos');

    }

  })
  .catch(console.log)
}


const bigbox_abrir = ( ) => {
  $('.bigbox-contenedor').css('display','block');
  $('.bigbox-contenedor').removeClass('bigbox-salida');
  $('.bigbox-contenedor').addClass('bigbox-entrada');
}

const bigbox_cerrar = () => {
  $('.bigbox-contenedor').removeClass('bigbox-entrada');
  $('.bigbox-contenedor').addClass('bigbox-salida');
  $('.bigbox-contenedor').css('display','none');
}


const Contacto = () => {
  return (
    <div>
        <Secondaryslider />
        <div className='espacio container py-4 izquierda'>
          <h3>Contacto</h3>
          <hr></hr>
          <div className='row'>
            <div className='col-12 col-md-6 vertical py-3'>
              <div>Para solicitar mayor información o requerimiento de soporte técnico, sírvase rellenar el siguiente formulario. No olvide escoger la opción de su necesidad. Un encargado de cada área responderá a la brevedad.</div>
              <div className='mt-4 h2'><i class="fa-solid fa-headset"></i><span className='h4'> ¿Necesitas soporte?</span> </div>
              <div>Atendemos tus sugerencias al <span className='h5'> <br className='tele'/>+57 300 929 5052</span></div>
              <div className='mt-3'><strong>Horario de atención:</strong> de lunes a viernes de 08.00 am a 05.00 pm.<br/> Sábados de 9.00 am a 12.00 pm</div>
            </div>
            
            <div className='col-12 col-md-6'>
              <form className="row g-3">
                <div className="col-md-6">
                  <label for="inputName" className="form-label">Nombre y Apellido</label>
                  <input type="text" className="form-control" id="nombre"></input>
                </div>
                <div className="col-md-6">
                  <label for="inputEmail4" className="form-label">Correo electrónico</label>
                  <input type="email" className="form-control" id="correo"></input>
                </div>
                <div className="col-6">
                  <label for="inputPhone" className="form-label">Teléfono</label>
                  <input type="text" className="form-control" id="tel" placeholder="(+001) 123 234 1234"></input>
                </div>
                <div className="col-md-6">
                  <label for="inputDepartment" className="form-label">Departamento requerido</label>
                  <select id="dpto" className="form-select">
                    <option value="">Escoja...</option>
                    <option value="Problemas con mi cuenta">Problemas con mi cuenta</option>
                    <option value="Problemas verificacion de imagen">Problemas verificación de imagen</option>
                    <option value="Asesoría tecnica">Asesoría técnica</option>
                    <option value="Administracion">Administración</option>
                  </select>
                </div>
                <div className="col-12">
                  <label for="inputDepartment" className="form-label">Consulta</label>
                  <textarea className="form-control" id="texto" placeholder="Su texto aquí"></textarea>
                </div>
                <div className="col-12">
                  <button type="submit" className="btn btn-warning btn-orange mb-4" onClick={enviarDatos}>Enviar consulta</button>
                </div>
              </form>
            </div>
          </div>
        </div>




        <div className='bigbox-contenedor'>
                
            <div className="bigbox-cerrar"><i class="fas fa-times" onClick={bigbox_cerrar}></i></div>
                  
            <div className="bigbox-contenido">
                <span className="bigbox-titulo"></span>
                <span className="bigbox-texto"></span>
            </div>
  
        </div>
    </div>
  )
}

export default Contacto