import React from 'react';
import './Bannerrojo.css';
import { Link } from 'react-router-dom'

const Bannerrojo = () => {
  return (
    <div className='fondorojo'><span className='espacioderecho estilo-uno mb-3'>No esperes más, regístrate ahora </span>
    <span><button type="button" class="btn btn-light"><Link className="btn btn-light" to="/registro"><strong>REGISTRARME</strong></Link></button></span> </div>


  )
}

export default Bannerrojo