import React from 'react'
import Mainslider from '../parts/Mainslider'
import Verpremios from '../parts/Verpremios'
import Video from '../parts/Video'
import Pasos from '../parts/Pasos'
import Premios from '../parts/Premios'
import Bannerrojo from '../parts/Bannerrojo'

const inicio = () => {
  return (
    <div>
        <Mainslider />
        <Verpremios />
        <Video />
        <Pasos />
        <Premios />
        <Bannerrojo />
    </div>
  )
}

export default inicio