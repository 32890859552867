import React from 'react';
import './Video.css';
import  imgvideo from '../img/video.png'

const video = () => {
  return (
    <div>
      <div className='container'>
        <div className='row py-4'>
          <div className='col-12 col-md-6 text-center centradoverticalvideo'>
            <h3>Presentación del concurso</h3>
            <p>"Fanáticos de la fotografía, coleccionistas de álbumes y amantes de la naturaleza". Así comienza nuestra guía, su presentación sobre este fabuloso concurso. Participa y gana!</p>


                      
                      <button type="button" className="btn btn-warning btn-orange mb-4" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      Ver Presentación
                      </button>

                      
                      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">Presentación del concurso</h5>
                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                            <iframe width="100%" height="420" src="https://www.youtube.com/embed/yJVkELwlYLo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div className="modal-footer">
                              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                            </div>
                          </div>
                        </div>
                      </div>

          </div>
          <div className='col-12 col-md-6'>
          <img className='anchort' src={imgvideo} alt="Logo" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default video